/* General App styles */
.App {
  text-align: center;
  padding: 20px;
}

/* Header styles */
.navbar {
  background-color: #ff9e9e;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar h1, .navbar h2 {
  margin: 0;
  padding: 0 15px;
}

.navbar h1 {
  font-size: 1.5em;
}

.navbar h2 {
  font-size: 1em;
}

/* Ensure h1 and h2 are centered */
.navbar h1, .navbar h2 {
  display: inline-block;
  margin: 0;
}

/* Adjust padding for smaller screens */
@media (max-width: 600px) {
  .navbar h1 {
    font-size: 1.2em;
  }
  .navbar h2 {
    font-size: 0.9em;
    display: none;
  }
}

/* Search button and Pagination styles (unchanged from earlier) */
.search-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0074d9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-button:hover {
  background-color: #005bb5;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.page-number {
  margin: 0 5px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.page-number:hover {
  background-color: #f0f0f0;
}

.page-number.active {
  background-color: #0074d9;
  color: white;
  border-color: #0074d9;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  flex-wrap: wrap;
}

.pagination li {
  display: inline;
  margin: 0 5px;
}

.pagination a {
  text-decoration: none;
  padding: 8px 16px;
  color: black;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.pagination a:hover {
  background-color: #ddd;
}

.pagination .active a {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

@media (max-width: 600px) {
  .pagination {
    font-size: 0.8em;
    flex-wrap: wrap;
  }

  .pagination li {
    margin: 2px;
  }

  .pagination a {
    padding: 6px 10px;
  }
}

/* AccountInfoList styles */
.account-info-list {
  padding: 20px;
}

.account-block {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  background: #f9f9f9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.account-id {
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
}

.ssr-count {
  font-size: 1.1em;
  color: #666;
}

.ssr-info-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ssr-info {
  flex: 1 1 auto;
  max-width: 100px;
  text-align: center;
}

.ssr-image {
  width: 80px;
}

/* Loading indicator styles */
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px; /* 可根据需要调整 */
}

.loading div {
  margin-top: 10px;
  font-size: 1.2em;
  color: #0074d9;
}

@media (max-width: 600px) {
  .loading {
    height: 100px; /* 在移动端调整高度 */
  }
}

.custom-link {
  color: #acec5e; /* 白色文字颜色 */
  text-decoration: none; /* 去掉下划线 */
  font-weight: bold; /* 加粗文字 */
  padding: 8px 16px; /* 内边距 */
  border-radius: 4px; /* 圆角 */
  transition: background-color 0.3s ease; /* 背景颜色过渡效果 */
}

.custom-link:hover {
  background-color: #125a9e; /* 鼠标悬停时的背景颜色 */
}
.account-info-list {
  padding: 20px;
}

.account-block {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  background: #f9f9f9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.account-id {
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
}

.ssr-count {
  font-size: 1.1em;
  color: #666;
}

.ssr-info-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ssr-info {
  flex: 1 1 auto;
  max-width: 100px;
  text-align: center;
}

.ssr-image {
  width: 80px;
  height: auto;
}
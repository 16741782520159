.ssr-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 增加间距 */
  justify-content: center;
  margin: 20px 0;
}

.ssr-selection h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.ssr-image {
  width: 120px; /* 调整后的 SSR 图片的宽度 */
  height: 120px; /* 调整后的 SSR 图片的高度 */
  cursor: pointer;
  transition: transform 0.2s;
}

.ssr-image:hover {
  transform: scale(1.1);
}
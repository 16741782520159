.selected-ssr {
    margin: 20px 0;
  }
  
  .selected-ssr h2 {
    text-align: center;
    margin: 10px 0;
  }
  
  .ssr-image.selected {
    border: 2px solid #0074d9; /* 选中的 SSR 图片边框颜色 */
    border-radius: 4px;
  }
  
  .ssr-selection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* 增加间距 */
    justify-content: center;
  }
  
  .ssr-image {
    width: 120px; /* 调整后的 SSR 图片的宽度 */
    height: 120px; /* 调整后的 SSR 图片的高度 */
  }